<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <UserMenu></UserMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-3">
        <v-row>
          <v-col cols="12">
            <h3>Manage Feedbacks</h3>
          </v-col>
        </v-row>

        <v-row class="rowBg">
          <v-col cols="2">
            <v-icon class="iconStyle">mdi-lightbulb-on-outline</v-icon>
          </v-col>

          <v-col cols="10">
            <h5>Guidelines</h5>
            <ol>
              <li>
                Please drop a feedback about a product you have purchased.
              </li>
              <li>
                Feedback will be published on the product you got.
              </li>
            </ol>
          </v-col>
        </v-row>

        <v-row class="rowBg mt-5">
          <v-col cols="12">
            <v-card color="basil">
              <v-tabs
                v-model="tab"
                background-color="#f2f2f2"
                color="basil"
                grow
              >
                <v-tab>
                  Orders Awaiting my Feedback (0)
                </v-tab>
                <v-tab>
                  View all my reviews
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card color="#f2f2f2" flat>
                    <v-card-title>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search by order No:"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        hide-actions
                        :headers="headers"
                        :items="awaitingFeedbacks"
                        :search="search"
                      >
                        <template slot="items" slot-scope="props">
                          <td>{{ props.item.student_reg_no }}</td>
                        </template>
                        <v-alert
                          slot="no-results"
                          :value="true"
                          color="error"
                          icon="warning"
                        >
                          Your search for "{{ search }}" found no results.
                        </v-alert>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card color="#f2f2f2" flat>
                    <v-card-title>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search by order No:"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        hide-actions
                        :headers="headers"
                        :items="awaitingFeedbacks"
                        :search="search"
                      >
                        <template slot="items" slot-scope="props">
                          <td>{{ props.item.student_reg_no }}</td>
                        </template>
                        <v-alert
                          slot="no-results"
                          :value="true"
                          color="error"
                          icon="warning"
                        >
                          Your search for "{{ search }}" found no results.
                        </v-alert>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserMenu from "../others/UserMenu.vue";

export default {
  name: "Orders",

  components: {
    UserMenu,
  },

  data: () => ({
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#/overview/user/dashboard/en",
      },
      {
        text: "Feedbacks",
        disabled: true,
        href: "/",
      },
    ],
    tab: null,
    search: "",
    headers: [
      {
        text: "Order Details",
        align: "left",
        sortable: false,
        value: "orderDetails",
      },
      { text: "Feddback", value: "feedback" },
      { text: "Feedback Status", value: "status" },
      { text: "Action", value: "action" },
    ],
    awaitingFeedbacks: [],
  }),
  methods: {},
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
}

.iconStyle {
  font-size: 40px;
  color: #dd6464;
  transform: rotate(180deg);
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
}

li {
  font-size: 12px;
  margin-top: 5px;
}
</style>
